.container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    z-index: 999;
    opacity: 0.5;
    display: flex;
    align-items: center;
    justify-content: center;
}

.text {
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999999;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 80%;
    text-align: center;
}

